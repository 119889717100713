import * as React from "react";
import { Link } from "gatsby";
import Layout from "../components/common/layout";

// styles
const codeStyles = {
  color: "#8A6534",
  padding: 4,
  backgroundColor: "#FFF4DB",
  fontSize: "1.25rem",
  borderRadius: 4,
};

// markup
const NotFoundPage = () => {
  return (
    <Layout>
      <section class="has-background-white" style={{ padding_bottom: "140px" }}>
        <div class="section pb-2 has-background-dark">
          <div class="container is-relative mb-5">
            <h2 className="has-text-white mb-4">Page not found</h2>
            <p className="has-text-white">
              Sorry{" "}
              <span role="img" aria-label="Pensive emoji">
                😔
              </span>{" "}
              we couldn’t find what you were looking for.
              <br />
              {process.env.NODE_ENV === "development" ? (
                <>
                  <br />
                  Try creating a page in{" "}
                  <code style={codeStyles}>src/pages/</code>.
                  <br />
                </>
              ) : null}
              <br />
              <Link to="/">Go home</Link>.
            </p>
          </div>
        </div>
      </section>
    </Layout>
  );
};

export default NotFoundPage;
