import React, { useState } from "react";
import { navigate } from "gatsby";
import Logo from "../../scss/images/logos/revcore-logo-backgroundless.svg";

import { Link } from "gatsby";

const Navbar = (props) => {
  const [isOpen, setIsOpen] = useState(false);

  const toggleCollapse = (e) => {
    e.preventDefault();
    setIsOpen(!isOpen);
  };

  const navTo = (e, dest) => {
    e.preventDefault();
    navigate(dest, { state: { showError: false } });
  };

  return (
    <nav className="navbar py-4">
      <div className="container">
        <div className="navbar-brand">
          <a className="navbar-item" href="/"
              onClick={(e) => {
                navTo(e, "/");
              }}
          >
            <Logo className="image" alt="" width="150px" height="100%" />
          </a>
          <a
            className={`navbar-burger ${isOpen ? "is-active" : ""}`}
            role="button"
            aria-label="menu"
            aria-expanded="false"
            onClick={(e) => {
              toggleCollapse(e);
            }}
            onKeyDown={(e) => {
              toggleCollapse(e);
            }}
          >
            <span aria-hidden="true"></span>
            <span aria-hidden="true"></span>
            <span aria-hidden="true"></span>
          </a>
        </div>
        <div className={`navbar-menu ${isOpen ? "is-active" : ""}`}>
          <div className="navbar-end">
            <a
              className="navbar-item"
              href="/"
              onClick={(e) => {
                navTo(e, "/");
              }}
            >
              Home
            </a>
            <a className="navbar-item" href="https://grintools.revcore.net">
              Grin Tools
            </a>
            </div>
        </div>
      </div>
    </nav>
  );
};

export default Navbar;
