import React from "react";

import Logo from "../../scss/images/logos/revcore-logo-backgroundless.svg";
import { navigate } from "gatsby";

const MainFooter = () => {
  const navTo = (e, dest) => {
    e.preventDefault();
    navigate(dest, { state: { showError: false } });
  };

  return (
    <footer className="footer">
      <section className="section">
        <div className="container">
          <div className="is-vcentered columns is-multiline">
            <div className="column is-8">
              <a
                className="is-inline-block"
                href="/"
                onClick={(e) => {
                  navTo(e, "/");
                }}
              >
                <Logo className="image" alt="" width="100px" height="100%" />
              </a>
              <div className="py-4 is-hidden-mobile"></div>
              <p className="is-hidden-mobile has-text-white">
                All rights reserved © Revcore Technologies Ltd. 2022
              </p>
            </div>
            <div className="column is-4">
              <div className="is-flex is-flex-wrap-wrap">
                <ul className="ml-auto mb-4 is-flex is-flex-wrap-wrap is-align-items-right">
                  <li>
                    <a
                      className="button is-dark has-background-default-grey"
                      href="/"
                      onClick={(e) => {
                        navTo(e, "/");
                      }}
                    >
                      Home
                    </a>
                  </li>
                 <li>
                    <a
                      className="button is-dark has-background-default-grey"
                      href="/team"
                      onClick={(e) => {
                        navTo(e, "/team");
                      }}
                    >
                      Team
                    </a>
                  </li>
                  <li>
                    <a
                      className="button is-dark has-background-default-grey"
                      href="/services"
                      onClick={(e) => {
                        navTo(e, "/services");
                      }}
                    >
                      Services
                    </a>
                  </li>
                </ul>
                <a
                  className="ml-auto button is-primary"
                  href="/contact"
                  onClick={(e) => {
                    navTo(e, "/contact");
                  }}
                >
                  Get in Touch
                </a>
              </div>
            </div>
          </div>
          <p className="is-hidden-tablet mt-4 has-text-center">
            <small>All rights reserved © Wireframes Corporation 2021</small>
          </p>
        </div>
      </section>
    </footer>
  );
};

export default MainFooter;
