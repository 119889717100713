import React from "react"
import PropTypes from "prop-types"

import MainFooter from "./footer"
import Navbar from "./navbar"

const Layout = (props) => {

  return (
    <>
      {/* Wrapper for sticky footer */}
      <div className="site">
        <Navbar/>
        <main className="site-content">{props.children}</main>
        <MainFooter />
      </div>
    </>
  )
}

Layout.propTypes = {
  children: PropTypes.node.isRequired,
}

export default Layout